<template>
  <page-layout ref="layout">
    <template #breadcrumbs="{ state, year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <b-breadcrumb-item text="Print" active/>
    </template>

    <template #actions="{ }">
      <b-button variant="outline-primary" size="sm" :disabled="table.busy" @click="print()">
        <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/> Print
      </b-button>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ }">
      <b-alert show dismissible variant="primary" class="d-print-none">
        <b-icon-info-circle class="mr-50"/>
        This view is readonly. <!--To make changes, please use the <u><b-link :to="{ name: 'all-state-selection', params: { slug: instrument.slug }}">Selections</b-link></u> view.-->
      </b-alert>

      <template v-id="!chaperones.loading && !studentEvents.loading">
        <b-table-simple v-for="chaperone in chaperones.items" :key="chaperone.id" responsive caption-top>
          <b-thead>
            <b-tr>
              <b-th colspan="4">{{ getFullName(chaperone) }}</b-th>
              <b-th colspan="4" class="text-right">{{ chaperone.hotelId ? chaperone.hotel.name : 'No Hotel' }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="col-10-percent">Room</b-th>
              <b-th class="col-20-percent">Student</b-th>
              <b-th class="col-10-percent">Sex</b-th>
              <b-th class="col-10-percent">Gender</b-th>
              <b-th class="col-20-percent">Ensemble</b-th>
              <b-th class="col-10-percent">Thursday</b-th>
              <b-th class="col-10-percent">Friday</b-th>
              <b-th class="col-10-percent">Saturday</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="studentEvents.loading" />
          <b-tbody v-else>
            <b-tr v-for="event in studentEvents.items.filter(studentEvent => studentEvent.chaperoneId === chaperone.id)" :key="event.id"
                  :data="application = event.student ? getApplicationWithSelection(event.student.applications) : null">
              <b-td>
              <span v-if="event.room">
                 {{ event.room }}
              </span>
                <span v-else class="text-danger">
                No Room
              </span>
              </b-td>
              <b-td>
              <span v-if="event.student">
                {{ getFullName(event.student) }}
              </span>
                <span v-else class="text-danger">
                No Student
              </span>
              </b-td>
              <b-td>
              <span v-if="event.student">
                 {{ event.student.sex }}
              </span>
                <span v-else class="text-danger">
                Unknown Sex
              </span>
              </b-td>
              <b-td>
              <span v-if="event.student">
                 {{ event.student.gender }}
              </span>
                <span v-else class="text-danger">
                Unknown Gender
              </span>
              </b-td>
              <b-td>
              <span v-if="application && application.selection && application.selection.ensemble">
                 {{ application.selection.ensemble.name }}
              </span>
                <span v-else class="text-danger">
                Unknown Ensemble
              </span></b-td>
              <b-td>
                <b-icon-square/>
              </b-td>
              <b-td>
                <b-icon-square/>
              </b-td>
              <b-td>
                <b-icon-square/>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </template>

    <template #debug>

    </template>
  </page-layout>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';


import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';
import settingsMixin from '@/mixins/settings.mixin';
import {getChaperones, getStudentEvents} from '@/views/events/service';
import events from '@/mixins/event.mixin';
import {uuid} from 'vue-uuid';

export default {
  name: 'CheckinModal',
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value)) : null
    }
  },
  components: {
    TableRowOptions,
    BCardActions,
    ApplicationGrading,
    ApplicationDetails,
    ApplicationStudent,
    ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin, events],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
   /* chaperones: {
      type: Array,
      required: false,
      default: () => []
    },*/
  },
  data() {
    return {
      user: null,
      instrument: null,
      ensembles: {
        loading: true,
        items: []
      },
      chaperones: {
        loading: true,
        items: [],
      },
      studentEvents: {
        loading: true,
        items: [ ],
      },
      applications: {
        loading: false,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
          /*{ key: 'group', label: 'Group', class: 'page-break' },*/
          { key: 'student', label: 'Student', class: 'col-student' },
          { key: 'festival.name', label: 'Festival', class: 'col-festival' },
          { key: 'festival.zone.name', label: 'Zone', class: 'col-zone' },
          { key: 'ranking.score', label: 'Score', class: 'col-score' },
          { key: 'ranking.local', label: 'Local Rank', class: 'col-local' },
          { key: 'selection.ranking.state', label: 'State Rank', class: 'col-state' },
          { key: 'selection.ensemble', label: 'Ensemble', class: 'col-ensemble' },
          { key: 'selection.part', label: 'Part', class: 'col-part' },
          /*{ key: 'row-options', label: '', tdClass: 'align-middle', thClass: 'border-0', class: 'table-row-options' },*/
        ],
        filter: {
          group: null,
          ensemble: null,
          part: null
        },
        search: null,
        items: [],
        initialItems: [],
        subscription: {
          onUpdate: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        error: {
          show: false,
          message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    sortedItems() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'group',
          'selection.ensemble',
          'selection.part',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.group) { query.$and.push({group: `'${this.table.filter.group}` }) }
      if(this.table.filter.ensemble) { query.$and.push({'selection.ensemble': `'${this.table.filter.ensemble}` }) }
      if(this.table.filter.part) { query.$and.push({'selection.part': `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}` },
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item).sort(this.compareItems())
      }
      else {
        items = this.table.items.slice().sort(this.compareItems())
      }

      return items.map((item, index) => ({...item,
        tabIndex: {
          state: (index + 1000),
          ensemble: (index + 1000) + this.table.items.length,
          part: (index + 1000) + (this.table.items.length * 2)
        }
      }))
    },


  },
  async created() {
    await this.load();
    await this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.layout.state.loading = false
      }, 500);
    })
  },
  methods: {
    async refresh() {
      await this.listStudentEvents()
    },

    async load() {
      await this.listChaperones()
      await this.listStudentEvents()
      //window.print()
    },

    async listChaperones() {
      this.chaperones.loading = true;
      await getChaperones({ includeHotel: true }).then(chaperones => {
        this.chaperones.items = chaperones;
        this.chaperones.loading = false;
        this.chaperones.loaded = true;
      })
    },

    async listStudentEvents() {
      this.studentEvents.loading = true;
      await getStudentEvents().then(students => {
        this.studentEvents.items = students;
        this.studentEvents.loading = false;
        this.studentEvents.loaded = true;
      })
    },

    print() {
      window.print()
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
  }
}
</script>

<style lang="scss">
@media print {

  .card .card-header {
    padding-top: 0!important;
  }

  .table-responsive {
    //overflow-x: visible;
  }

  table {
    page-break-after: always;
    break-after: page;
    //margin: 0.5rem
  }

  table * {
    font-size: 11px!important;
  }

  .td-details-row {
    display: none!important;
  }

  thead {
    page-break-before:unset!important;
    page-break-inside: unset!important;
    page-break-after:unset!important;
  }
}

</style>

<!--
<style>
.td-details-row {
  border-top: 0!important;
  padding: 0!important;
}

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}

.b-table-details > td > table > tbody > tr:first-child > td {
  border-top: 0!important;
}

.tr-group-2 {
  background-color: #f5f5f5!important;
}
.rounded-bottom-only {
  border-radius: 0 0 0.357rem 0.357rem;
}

.alert .list-group .list-group-item:hover {
  background-color: #fff;
}
.ps-changes {
  max-height: 300px;
}
.ps&#45;&#45;active-y.ps-changes {
  padding-right: 1.5rem
}
.ps-processed {
  max-height: 50vh;
}
.ps&#45;&#45;active-y.ps-processed {
  padding-right: 1.5rem
}
.alert .list-group .list-group-item:hover {
  background-color: #fff;
}


@media (max-width: 1400px) {
  .table th.col-local > div {
    display: none;
  }

  .table th.col-local::after {
    content: "Local";
    display: block;
  }
  .table th.col-state > div {
    display: none;
  }

  .table th.col-state::after {
    content: "State";
    display: block;
  }
}


@media print {

  .modal-dialog {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-dialog-centered {
    align-items: start;
    min-height: calc(100% - 1rem);
  }

  .table th.col-local > div {
    display: none;
  }
  .table th.col-local::after {
    content: "LR";
    display: block;
  }
  .table th.col-state > div {
    display: none;
  }
  .table th.col-state::after {
    content: "SR";
    display: block;
  }

}

</style>

<style lang="scss" scoped>
::v-deep {
  .col-student {
    width: 20%;
    vertical-align: middle !important;
  }
  .col-festival {
    //width: 10%;
    vertical-align: middle !important;
  }
  .col-score {
    //width: 10%;
    vertical-align: middle !important;
  }
  .col-local {
    //width: 10%;
    vertical-align: middle !important;
  }
  .col-state {
    width: 10%;
    vertical-align: middle !important;
  }
  .col-ensemble {
    width: 20%;
    padding-left: 0!important;
    padding-right: 0!important;
    vertical-align: middle !important;
  }
  .col-day {
    width: 5%;
    vertical-align: middle !important;
  }

  @media print {
    table {
      page-break-after: always;
    }

    table * {
      font-size: 12px!important;
    }

    .td-details-row {
      display: none!important;
    }

    thead {
      page-break-before:unset!important;
      page-break-inside: unset!important;
      page-break-after:unset!important;
    }

    .card .card-header {
      padding-top: 0!important;
    }
    .form-control {
      border: 0!important;
    }
    .vs__dropdown-toggle {
      border: 0!important;
    }
    .vs__actions {
      display: none!important;
    }
    .media-aside {
      margin-right: 0;
    }
    .b-avatar {
      display: none;
    }
    .col-student {
      width: auto!important;
    }
    .col-festival {
      width: auto!important;
    }
    .col-score {
      width: auto!important;
    }
    .col-local {
      width: auto!important;
    }
    .col-state {
      width: auto!important;
    }
    .col-ensemble {
      width: auto!important;
    }
    .col-day {
      width: auto!important;
    }
  }

  /*@media (max-width: 1530px) {
    !*.media-aside {
      display: none!important;
      margin-right:0!important;
    }
    .b-avatar {
      display: none!important;
    }*!
    .col-state {
      width: 12%!important;
    }
    .col-ensemble {
      width: 22%!important;
    }
    .col-day {
      width: 12%!important;
    }
    .student-school {
      display: none!important;
    }
    .festival-prefix {
      display: none!important;
    }
  }*/

  @media print {
    table {
      page-break-after: always;
    }

    table * {
      font-size: 12px!important;
    }

    .td-details-row {
      display: none!important;
    }

    thead {
      page-break-before:unset!important;
      page-break-inside: unset!important;
      page-break-after:unset!important;
    }

    .card .card-header {
      padding-top: 0!important;
    }
    .form-control {
      border: 0!important;
    }
    .vs__dropdown-toggle {
      border: 0!important;
    }
    .vs__actions {
      display: none!important;
    }
    .media-aside {
      margin-right: 0;
    }
    .b-avatar {
      display: none;
    }
    .col-student {
      width: auto!important;
    }
    .col-festival {
      width: auto!important;
    }
    .col-score {
      width: auto!important;
    }
    .col-local {
      width: auto!important;
    }
    .col-state {
      width: auto!important;
    }
    .col-ensemble {
      width: auto!important;
    }
    .col-day {
      width: auto!important;
    }
  }
}

/*.v-select::v-deep {
  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 40px); !* change this to `- 40px` if you're supporting a `clearable` field; I was not *!
  }
  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}*/
</style>
-->

